
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.search-ticket {
  position: relative;
  margin-left: 8px;
  cursor: pointer;

  @include for-size(phone-portrait-down) {
    margin-left: 0;
  }

  &::before {
    content: '';
    width: 24px;
    height: 76px;
    background-color: rgba(47, 154, 255, 0.6);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    position: absolute;
    top: 0;
    left: -8px;
    z-index: 0;

    @include for-size(phone-portrait-down) {
      width: 96px;
      height: 24px;
      top: -8px;
      left: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 12px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 8px;

    @include for-size(phone-portrait-down) {
      margin-bottom: 24px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    background-color: $color-white;
    padding: 20px;
    border-radius: 16px;

    @include for-size(phone-portrait-down) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__label {
    display: none;

    @include for-size(phone-portrait-down) {
      display: block;
      font-size: 12px;
      line-height: 16px;
      color: $color-black-op-50;
      margin-bottom: 6px;
    }
  }

  &__value {
    @include for-size(phone-portrait-down) {
      margin-bottom: 6px;
    }
  }

  &__service {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 20px;
  }

  &__info {
    width: 25%;
    padding-right: 16px;
    flex-shrink: 0;

    @include for-size(phone-portrait-down) {
      padding-right: 0;
      width: 100%;
    }
  }

  &__number {
    font-size: 14px;
    line-height: 20px;
    color: $color-black-op-50;
    margin-bottom: 6px;
  }

  &__desc {
    width: 25%;
    flex-shrink: 0;
    align-self: flex-start;
    padding-right: 16px;

    @include for-size(phone-landscape-down) {
      width: 22%;
    }

    @include for-size(phone-portrait-down) {
      padding-right: 0;
      width: 100%;
    }
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
  }

  &__description {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-70;
  }

  &__date-executed {
    width: 10%;
    flex-shrink: 0;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding-right: 16px;

    @include for-size(phone-landscape-down) {
      font-size: 12px;
      line-height: 16px;
      width: 13%;
    }

    @include for-size(phone-portrait-down) {
      padding-right: 0;
      width: 100%;
      text-align: left;
    }
  }

  &__status {
    width: 22%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 16px;

    // TODO: скорее всего нужно доработать esmp-tag, чтобы нормально отображалось с ellipsis
    .esmp-tag {
      @include for-size(phone-landscape-down) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        line-height: 16px;
        padding: 4px 8px;
      }
    }

    @include for-size(phone-portrait-down) {
      padding-right: 0;
      width: 100%;
      align-items: flex-start;
    }
  }

  &__customer {
    width: 18%;
    flex-shrink: 0;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;

    @include for-size(phone-portrait-down) {
      padding-right: 0;
      width: 100%;
    }
  }

  &__avatar {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-right: 8px;
  }
}
